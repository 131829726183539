import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import APIData from "../../content/api/index.json"
import Api from "../../components/misc/api"
import HeroSubpage from "../../components/heroes/hero-subpage"

const APIPage = ({ intl }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={APIData[intl.locale].title}
             description={APIData[intl.locale].text}
        />
        <HeroSubpage
            theme={`dark`}
            content={APIData[intl.locale]}
        />
        <Api content={APIData[intl.locale]} />
    </Layout>
)

export default injectIntl(APIPage)
