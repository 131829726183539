import React from 'react';
import { Row, Col, Container } from "react-bootstrap"
import {injectIntl} from "gatsby-plugin-react-intl"
import SimpleCard from "./simple-card"

class API extends React.Component {

  render() {
        return (

            <div className={`api`}>
                <Container>
                  <Row>
                    {this.props.content.api.map((content, i) => {
                      return (
                        <Col key={i} lg={6}>
                          <SimpleCard content={content} />
                        </Col>
                      )
                    })}
                  </Row>
                </Container>
            </div>
        )
    }
}

export default injectIntl(API)
